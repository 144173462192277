@mixin groupHeader(){
	background: #e5e5e5;
}

.c-section {
	.c-title {
		@include groupHeader();
		@extend .px-2, .py-1, .my-1;
	}
	.c-list {
		@extend .px-2;
		text-align: left;

		.c-item {
			@extend .py-2;
			text-align: left;

			.o-label {
				display: inline-block;
				width: 20vw;
				@extend .mr-1;
				
				@include font-size(14px);
				color: grey;
				&:after {
					content:":";
				}
			}
			.o-value.highlight {
				font-weight: 600;
			}
		}

		.c-item + .c-item {

			@extend .mt-0;
			border-top: 1px solid #d0d0d0;
		}
	}

	&--first {
		.c-name {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.o-name {
				@include font-size(21px);
				font-weight: 600;
			}
		}
		.c-list {
			@extend .py-2;
		}
		.c-list .c-item {
			padding: 0 !important;
		}
		.c-item + .c-item {
			border-top: none !important;
		}
	}

}
.l-modal--center {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

/*


.l-page--qr-medinfo {
  min-height: 100vh;
  background-color: $gray-300;
  // @extend .l-container;
  .o-title-main {
    text-align: center;
    margin: 20px;
  }
	
  [class^="c-section"] {
    &.c-section__first {
      margin: 1rem;
      margin-bottom: 2rem;
    }
    .o-section-title {
      margin: 0.5rem 0;
      text-align: center;
      font-size: x-large;
      width: 100%;
      background-color: $brand;
      color: $brand-light;
    }
    .c-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      [class^="o-strong-title"] {
        font-weight: bold;
        font-size: large;
        &.o-strong-title__name {
          font-size: 1.5rem;
          margin-bottom: 1rem;
        }
      }
      .c-item {
        @extend .row;
        @extend .container;
        margin: 0.1rem;
        padding: 0;
        span {
          @extend .col;
          overflow-wrap: break-word;
          .b-icon {
            margin-right: 5px;
          }
        }

        .c-group-two {
          display: flex;
          flex-direction: column;
          span {
            line-height: 1rem;
            padding: 0;
            &.o-indent {
              margin-left: 0.5rem;
            }
          }
        }
      }
    }
  }
  main {
    display: flex;
    justify-content: center;
    .c-list {
      @extend .col;
      @import "./../components/respond/info-item";
    }
    .l-details {
      @extend .col;
		  @import "./../components/respond/info-detail";
    }
  }
}
*/
