
&, .l-contact-picker {
	
	@mixin contact-item {
		@extend .p-2;
	
		display: flex;
		justify-content: space-between;
		align-items: center;
		
		border-bottom: 1px solid #d0d0d0;
		
		.o-button {
			@extend .btn, .btn-primary, .btn-sm;
		}
	
		.c-label {
			display: flex;
			align-items: center;
	
			.c-favicon {
				cursor:pointer;
				@extend .mr-2;
			}
			.o-name {
				font-weight: 600;
				@include font-size(19px);
				line-height: 1;
			}
			.o-relation {
				@include font-size(14px);
			}
			.o-phone {
				@include font-size(15px);
			}
		}
	
		&.caller {
			.o-name {
				font-weight: 800;
			}
			background: #fafafa;
			border-top: 1px solid silver;
			border-bottom: 1px solid silver;
	
			.o-button {
				@extend .btn-primary-alt;
			}
		}
		
	}

	$panel-width: 25vw;

	position: fixed; 
	z-index: 961970;

	right: 0;
	top: $header-height;
	height: calc(100vh - #{$header-height});
	width: $panel-width;
	overflow-y: auto;

	@extend .py-2;
	
	background:white;
	border-left: 1px solid gray;
	filter: drop-shadow(-7px 0px 2px #7777);
	

	&.slide-leave-active,
	&.slide-enter-active {
		transition: all .75s ease-out;
	}
	&.slide-enter {
		transform: translateX(25vw);
	}
	&.slide-leave-to {
		transition: all .5s ease-out;
		transform: translateX(25vw);
	}


	
	.c-tabs__nav {
		@extend .mx-2, .px-1, .mt-2;
		background: #f0f0f0;
		border-radius: 4px;

		display: flex;
	
		.nav-item {
			@extend .py-1;

			flex: 1 1 auto;

			display: flex;
			justify-content: center;

			.nav-link {
				@extend .py-1;
				width: 100%;
				text-align: center
			}
		}
	}

	.tabs {

		.tab-content {}

		.tab-pane {
			@extend .px-2, .mt-2;
	
			&--is-empty {
				@extend .my-6;

				display: flex;
				align-items: center;
				justify-content: center;
	
				.c-msg--warning {
					@extend .p-3;
					background: rgba($warning, .1);
				}
			}

			.c-contact-item {
				@include contact-item();
			}
		}
	}


	.tab-pane__favourites {}

	.tab-pane__contacts {
		.c-contact-item {
			.c-label {
				display: flex;
				flex-direction: column;
				align-items: flex-start !important;
				.c-name {
					display: block;
				}
			}
		}
	}

	.tab-pane__search {

		// kad je odabran department
		.c-header {
			@extend .py-2;

			display: flex;
			align-items: center;
			.c-action {
				@extend .mr-2;
			}
			.o-title {
				font-weight: 600;
				@include font-size(19px);
			}
			border-bottom: 2px solid #d0d0d0;
		}

		.c-search-item {

			@extend .px-2;

			display: flex;
			align-items: center;
			justify-content: space-between;

			border-bottom: 1px solid #d0d0d0;
			&:first-child {
				border-top: 1px solid #d0d0d0;
			}
			.c-label {
				@extend .py-2;
				.o-name {

					font-weight: 600;
					@include font-size(19px);
					line-height: 1;
					display: block;
				}
				.o-city {
					@include font-size(15px);
				}

			}
			.c-icon {
				// border-left: 1px solid #d0d0d0;
				height: 100%;
				@extend .p-2;
				// .bi {@extend .ml-1;}
			}
			&:hover {
				cursor: pointer;
				background: #f0f0f0;
			}
		}


		.c-list-department-contacts {
		
			.c-contact-item {
				.o-name {
					font-weight: 400 !important;
				}
			}
		}
	}
	
}


