.l-header {
  display: flex;
  justify-content: space-between;
	border-bottom: 1px solid $brand-dark;
	height: $header-height;

	&__right {
		position: relative;
		display: flex;
		align-items: center;
	}
  .c-code-id {
    margin-right: 10px;
    font-size: 30px;
		font-weight: 600;
	}

	&__left {
		max-height: 72px;
		overflow-y: hidden;
		.c-logo {
			height: 52px;
		}
	}
}
