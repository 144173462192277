#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
	display: flex;
	flex-direction: column;
}
.app-portal {
	@extend .dev-border, .v-2;
	.main-nav {
		display: none;
		// @extend .mt-n7;
	}
}
