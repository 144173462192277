

.l-header {
	@extend .col-12, .mb-3;

	display: flex;
	align-items: center;
	justify-content: space-between;

	@extend %border-bottom;
}

.l-body {
	@extend .row;
	height: 100%;
	overflow: hidden;
	max-height: 100%; //?

	.l-summary {
		@extend .col-6;

		overflow: auto;
		height: 100%;
		
		//FIXME ovo je fix jer ne prepoznaje visinu dobro
		@extend .pb-7; 

		border-right: 1px solid color(bblack);

		@import "info-item";
	}
	.l-details {
		@extend .col;

		@import "info-detail";

	}
}
.l-controls {
	@extend .pb-2, .mb-3;

	display: flex;
	justify-content: flex-end;
	
	@extend %border-bottom;
	border-bottom-color: color(bdark);
}
