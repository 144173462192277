.l-shop {
  @extend .container;
  text-align: center;
  max-width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  .progress {
    width: 100%;
    margin-bottom: 1rem;
  }
  form {
    width: 100%;
    text-align: center
  }
  .o-subtitle {
    @extend .h5;
    font-weight: 100;
    color: color("gray");
    margin-bottom: 1rem;
  }
  .l-go-to-checkout {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .jumbotron {
      width: 100%;
    }
  }
  .l-select-subscription {
    width: 100%;
    .l-group-cards {
      flex-direction: column;
      align-items: center;
      width: 100%;
      .card {
        cursor: pointer;
        padding: 0;
        width: 100%;
        margin-bottom: 2rem;
        border-width: 3px !important;
      }
    }
  }
}