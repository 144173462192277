/*
	Welcome (enter code)
*/
.l-form--enter-code {
	@extend .l-container--narrow;

	.l-page__main {
		@extend .col-12, .col-lg-6, .offset-lg-3;
		text-align: center;
	}

	.l-page__header {
		@extend .mb-6;
		.c-logo {
			@extend .mb-3;
		}
	}

	.c-form {
		.error {
			display: block;
			@extend .pt-1;
			color: $danger;
			text-align: left;
		}

		.btn {
			width: 100%;
			@extend .my-6;
		}
	}
}

.l-page--first-responder {

	.l-form--enter-code {
		@extend .l-container--narrow;
	
		.l-page__main {
			@extend .col-12, .col-lg-6, .offset-lg-3;
			text-align: center;
		}
	
		.l-page__header {
			@extend .mb-6;
			.c-logo {
				@extend .mb-3;
			}
		}
	
		.c-form {
			.error {
				display: block;
				@extend .pt-1;
				color: $danger;
				text-align: left;
			}
	
			.btn {
				width: 100%;
				@extend .my-6;
			}
		}
	}

	.l-dashboard  {
		@extend .row;
		@extend .mt-n7, .p-3;

		height: calc(100vh - 64px);
		overflow: hidden;

		.l-col {
			@extend .col-12, .col-lg;
			max-height: 100%;

			&--map {
				@extend .col-lg-5;

				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
			&--info {
				// overflow: auto;
			}
		}
		
		.l-map {
			@extend .mb-6;
			min-height: 60vh;
			flex: 1 1 100%;
			position: relative;

			.c-map {
				height: 100%;
				width: 100%;
			}
			.btn-directions {
				position: absolute;
				margin: 30px 10px;
				bottom: 0;
				left: 0;
			}
		}
		.l-logs {
			flex: 0 0 auto;

			@import "components/respond/logs";
		}
		.l-info {
			position: relative;
			height: 100%;
			overflow: hidden;
			
			@import "../components/respond/info";
		}
		
		.l-video {
			@import "../components/respond/video";
		}

	}
}
