.c-info-item {

	border: 1px solid #d0d0d0;
	@extend .mb-3;

	
	.c-header {
		display: flex;
		align-items: center;
		background: #fafafa;
		outline: 0;
		
		&.not-collapsed {
			border-bottom: 2px solid #d0d0d0;
		}

		
		.o-title {
			@extend .h4, .px-3, .pt-3, .pb-1;

			// @extend %border-bottom;
			// border-width: 2px;
		}
	}
	.c-list {
		@extend .list-unstyled;
		@extend .mb-0;

		.c-item {
			@extend .px-2, .py-3;

			display: flex;
			align-items: center;
			justify-content: space-between;

			&:not(:last-child){

				@extend %border-bottom;
				border-color: #d1d1d1;
			}

			cursor: pointer;
			&:hover {
				background: color(blight);
			}
			&.is-selected {
				// background: rgba(#d1d1d1, .5);
				background: rgba(color(brand), .05);
				font-weight: bold;
				pointer-events: none;
			}
		}
	}
}
