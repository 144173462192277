.l-page--register-second-partner {
	@extend .l-container;

	.l-page__body {
		@extend .row;
	}
	.l-col {
		@extend .col-12, .col-lg-8;
		&:first-child{
			@extend .col-lg;
		}
	}

	.c-form {

		.card-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.o-title {
				@extend .h4;
				@extend .mb-0;
			}
			.btn-transparent {
				@extend .m-0, .p-0;
				&:after {
					content: "_";
					@extend .h3;
					font-weight: 100;
				}
				&.collapsed {
					&:after {
						content: "+";
					}
				}
			}
		}
		.l-col--2 {
			.card {
				@extend .mb-5;
			}
		}
	}
	.c-form__buttons {
		display: flex;
		flex-direction: column;
	}
}
