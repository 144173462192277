.c-info-detail {
	.c-header {
		@extend .mb-4;
	}

	.c-list {
		
		.c-item {
			@extend .mb-3;
		}
		
		.o-label {
			@include font-size(16px);
			line-height: 1;
		}
		.o-value {
			@include font-size(21px);
			font-weight: bold;
		}
	}
}
