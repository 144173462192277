.l-my-info {

	$border-color: #0003;

	@extend .l-container--fluid;
	.l-col {
		@extend .col-12, .col-lg-4;
		&--details {
			@extend .col-12, .col-lg-8;
		}
	}

	.l-col--details {
		
		border-left: 1px solid $border-color;
		
		.c-info {
			@extend .row;
		}
		.c-info__header {
			@extend .col-12;
			border-bottom: 1px solid $border-color;
		}
		.c-info__summary, .c-info__description {
			@extend .col-12, .col-lg-6;
		}
		.c-info__description {
			border-left: 1px solid $border-color;
		}
	}

	/*
		Views
	*/
	// locations
	.c-my-locations {
		.c-list {

			.l-item {
				@extend .col-12, .py-2, .mb-2;
				border: 1px solid #d0d0d0;

				//
				.c-item {
					&__icon {
						width: 52px;
						height: 52px;
						@extend .mr-2;

						display: flex;
						align-items: center;
						justify-content: center;
						// .o-image {}

					}
					
					display: flex;
					align-items: center;

					
					
					&.selected {
						opacity: 0.4;
					}
					.o-title {
						@extend .h4, .mb-0, .pb-0;
						line-height: 1;
					}
				}
				&:first-child { 	background-color: #10CC0010;}
				&:nth-child(2) {	background-color: #FF990010;}
				&:nth-child(3) {	background-color: #0E85F310;}
				&:nth-child(4) {	background-color: #AD00FF10;}
			}
		}
	}

	.c-my-contacts {
		.c-title {
			@extend .mb-2;

			display: flex;
			justify-content: space-between;
			align-items: center;
			// border-bottom: 2px solid $border-color;
		}
		.c-list {
			.c-item {
				@extend .mb-2, .py-2, .px-3;
				border: 1px solid #d0d0d0;
				.o-subtitle {
					@extend .mb-0;
					line-height: 1;
				}
				.o-subtitle {
					@extend .mb-0;
				}
			}
		}
	}

	.c-info {
		&__header {
			display: flex;
			justify-content: space-between;
		}
		@import "components/respond/info-item";

		.c-info-item {
			&:first-child {
				@extend .mt-3;
			}
			.c-title {
				width: 100%;
				display: flex;
				justify-content: space-between;
			}
		}
	}
	.c-info__description {

		.c-title {
			@extend .my-4;
			text-decoration: underline;
		}
		.form-group {
			label {
				@extend .mb-0;
			}
		}
		.form-group + .form-group {
			@extend .mt-4;
		}
		.c-actions {
			justify-content: flex-end;
			&:before {
				content: "";
				width: 100%;
				height: 1px;
				background: #d0d0d0;
				@extend .mt-4, .mb-3;
			}
			.btn {
				@extend .px-4;
			}
			.btn + .btn {
				@extend .ml-2;
			}
		}
	}
}

@import './../components/modal/update-location';
