$header-height: 72px;


body, html, #app{
	min-height: 100vh;
}

.c-footer {
  text-align: center;
	background-color: $brand-dark;
	color: $brand-light;
	height: 2rem;

	display: flex;
	align-items: center;
	justify-content: center;
}


.l-page {
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: calc(100vh - #{$header-height} - 2rem);
	@extend .py-7;
	.l-page__header {
		position: relative;
		display: inline-block;
		@extend .mb-4;

		// .o-title {
		// 	@extend %underline-title;
		// }
		.c-btn-icon {
			@extend .h2;
			position: absolute;
			left: -$h2-font-size - $spacer-4;
			color: color(brand-alt);
		}
	}
}

.l-container {
	@extend .container;
}
.l-container--fluid {
	@extend .container-fluid;
}
.l-container--full {
	@extend .container-fluid;
	@extend .mx-0, .px-0;
}
.l-container--narrow {
	@extend .container;
	@include media-breakpoint-up(lg){
		max-width: 800px;
	}
	// @extend .col-12, 
	// 	.col-lg-8, .offset-lg-2,
	// 	.col-xxl-6, .offset-xxl-3;
}

.l-col--fixed {
	position: fixed;
}
