$isDev: false;


.dev-border {
	border: 1px dashed magenta;
	border-width: 0;
	@if($isDev) {
		border-width: 1px;
		border-color: magenta;
	}
	&.v-2 { 
		border-color: green; 
	}
}

.dev-bg {
	@if($isDev) {
		background: #9992;
	}
	&-red {
		@if($isDev) {
			background: red;
		}
	}
}

//TODO vidit šta ćemo s ovim?

.router-link-active { 
  text-decoration: none;
  color: grey !important;
}


// fade router animation
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

// sakrit sve osim jedne kontrole
// .moveable-direction {
// 	display: none;
// 	.moveable-ne {
// 		display: block;
// 	}
// }


// .l-first-responder  {
//   position: relative;
//   display: grid;
//   grid-template-columns: repeat(5, 1fr);
//   grid-template-rows: 30vh auto auto;  
//   grid-template-areas:
//     "map map map map map"
//     "logs logs logs logs logs"
//     "info info info info info";
//   grid-gap: 5px;
// }

// @media screen and (min-width: 900px){
//   .l-first-responder{
//     display: grid;
//     grid-template-rows: 65vh calc(100vh - 65vh - 125px);  
//     grid-template-areas:
//       "map map info info info"
//       "logs logs info info info";
//     grid-gap: 5px;
//   }
// }
