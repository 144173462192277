
.floater {

	$app-width: 600px;

	position: absolute;
	left: calc( 50vw - #{$app-width / 2});
	top: 25vh;

	width: $app-width;
	// border: 1px solid red;
	z-index: 100000;

	.video .l-item {
		width: 100%;
	}

  .videos {
    position: relative;
    display: flex;
  }
  // s ovim možemo diktirat glavni video 16by9 ili 21by9 - al ovo je najbolje možda
  .video__main {
    @extend .embed-responsive, .embed-responsive-4by3;
    width: 75%;
    flex: 0 0 75%;

    .video {
      @extend .embed-responsive-item;
      background: grey;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .video__thumbs {
    
    flex: 0 0 25%;
    background: silver;
    
    display: flex;
    flex-direction: column;
    
    .video__thumb {
      flex: 1;
      @extend .px-2;
      &:first-child {
        @extend .pt-2, .pb-1;
      }
      &:nth-child(2){
        @extend .py-1;
      }
      &:last-child {
        @extend .pb-2, .pt-1;
      }
      
      display: flex;
      align-items: center;
      justify-content: center;
      
      .video {
        display: flex;
        align-items: center;
        justify-content: center;
				background: whitesmoke;

        width: 100%;
        height: 100%;
				&:hover {
					cursor: pointer;
				}
      }
    }
  }

  .size-control {
    position: absolute;
    right: -3px; bottom: -3px;
    width: 12px; height: 12px;
    background: color(brand-alt);
		outline: 2px solid white;

		cursor:nswe-resize;
    &:hover {
      cursor:nswe-resize;
    }
}

  .controls__top {
    background: black;
  }
  .controls__bottom {
    display: flex;
    justify-content: center;

    background: black;

    .btn {
      @extend .btn-primary, .btn-sm;
      @extend .mx-1;
    }
  }

}


.l-video-chat__controls {
  width: 100%;
  text-align: center;
  display: block;
  z-index: 2;
  @extend .mx-auto, .py-2;
  .btn {
    @extend .mr-2;
  }
}

.l-contact-picker {
  @import "contact-picker";
}
  

