.l-page--home {
	@extend .l-container;
	
	.l-page__main {
		@extend .row;
	}
	.l-page__header {
		@extend .col-12, .mb-6;
		text-align: center;
		// @extend .dev-border;
		.o-title {
			@extend .h1, .mb-1;
			line-height: 1;
		}
		.o-subtitle {
			@extend .h5;
			font-weight: 100;
			color: color("gray");
		}
	}
	.l-page__body {
		@extend .col-12;

		.l-codes,
		.l-controls {
			@extend .row; 
		}
		.l-col {
			@extend .col;
			text-align: center;
			.card {
				height: 100%;
				&-title {
					@extend .h5;
				}
				.card-body {
					display: flex;
					flex-direction: column;
					.code {
						@extend .mt-auto;
					}
				}
			}
		}
		.l-controls--buttons-alone {

			
			.l-card__body {
				display: flex;
				justify-content: space-between;
				align-items: center;
	
				.l-controls--left {
					display: flex;
					.btn {
						@extend .ml-1;
					}
				}
				.l-controls--right {
					.btn {
						min-width: 240px;
					}
				}
			}
		}
/*
		// prvi koncept (dva stupca)
		.l-controls {
			.l-col {
				text-align: left;

				&:first-child {
					@extend .col-lg-7, .col-xxl-8;
					.btn {
						display: block;
						width: auto;
						@extend .btn-sm;

						position: absolute;
						right: $spacer-2;
						top: $spacer-2;
					}
				}
				&:last-child {
					.btn.js-alert {
						@extend .mb-4;
					}
					.c-nested-controls {
						display: flex;
						justify-content: space-between;
						.btn {
							flex: 1 1 auto;
							&:first-child{
								@extend .mr-2;
							}
						}
					}
				}
			}
		}
*/
		.hr {
			@extend .my-3;
		}
	}

}
