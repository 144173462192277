%border-bottom {
	border-bottom: 1px solid color(bblack);
}

%underline-title {
	&:before {
		position: absolute;
		content: "";
		display: block;
		width: 100%;
		padding-right: 140px;
		height: 2px;
		background: rgba(silver, 0.2);
		bottom: $h1-font-size / 2.5;
		z-index: -1;
	}
}


.hr--vertical {
	flex: 0 0 1px;
	width: 1px;
	height: 100%;
	min-height: 1vmin;
	border: none;
	background: $brand-dark;
	margin: 0;
	padding: 0;
	@extend .mx-2, .my-auto;
}

// bootstrap icon
.c-btn-icon {
	@extend .btn;
	@extend .m-0, .p-0;
	display: inline-block;
	background: transparent;
}


// scrollbar style
.c-list {
	@extend %scrollbar;
}
%scrollbar {
	scrollbar-color: #000000 #F5F5F5;
  scrollbar-width: thin;
	::-webkit-scrollbar-track{
		-webkit-box-shadow: inset 0 0 7px rgba(0,0,0,0.3);
		box-shadow: inset 0 0 7px rgba(0,0,0,0.3);
		background-color: #F5F5F5;
	}
	
	::-webkit-scrollbar {
		width: 6px;
		background-color: #F5F5F5;
	}
	
	::-webkit-scrollbar-thumb { background-color: #000000;}
}


// chrome outline focus issues
div:focus {
	outline: 0;
}
