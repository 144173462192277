.l-page--login {
	@extend .l-container--narrow;

	.l-page__header {
		position: relative;
		display: inline-block;
		@extend .mb-4;

		.o-title {
			@extend %underline-title;
		}

	}

	.l-page__main {
		@extend .row;
		@extend .pt-5;

		height: 100%;
		display: flex;
	
		// @extend .dev-border;
	}
	.l-page__logo {
		@extend .col-12, .col-lg-5;
		.o-image {
			max-width: 30vmin;
		}
		}
	.l-page__body {
		@extend .col-12, .col-lg-5, .offset-lg-1,;
		text-align: left;
	}

	//

	.c-form__login {
		@extend .mb-6;

		.form-group {
			@extend .mb-3;
			label {
				display: inline-block;
				@extend .mb-0;
				color: color("gray");
			}
		}
		
		.btn {
			width: 100%;
			@extend .mt-2;
		}
	}
	.c-form__login-sso {
		.o-title {
			color: $gray-500;
			text-align: center;
		}

		#appleid-signin { //apple button
			div {
				max-width: 100% !important
			};
		}
		.google-btn {
			width: 100%;
			@extend .my-3;
			
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			svg {
				@extend .mr-2;
			}
			border-radius: $border-radius *2;
		}
	}
}
