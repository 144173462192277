.o-image {
	width: 100%;
	height: auto;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 600;
}
.h1 { @extend h1;}
.h2 { @extend h2;}
.h3 { @extend h3;}
.h4 { @extend h4;}
.h5 { @extend h5;}
.h6 { @extend h6;}

button,
button.btn,
button.btn:focus,
a.btn {
	outline: 0 !important;
	box-shadow:none !important;
}



// slide-in-out router animation
.slither-enter-active, .slither-leave-active {
  transition: transform 0.5s;
}

.slither-enter, .slither-leave-to {
  transform: translateX(-100%);
}

.slither-enter-to, .slither-leave {
  transform: translateX(0);
}
