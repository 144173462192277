&.c-logs {

	.c-header {
		position: relative;
		@extend .py-2, .mb-3;

		display: flex;
		align-items: center;

		border-bottom: 1px solid black;
		
		.c-icon {
			display: block;
			flex: 0 0 32px;
			@extend .mr-2;

			.o-icon {
				width: 100%;
				height: 100%;
			}
		}

		.o-title {
			@extend .h3, .mb-0;
		}
		.o-subtitle {
			@extend .h5, .mb-0;
		}
	}
	.c-logs__details {
		@extend .ml-3;
		.o-title {
			@extend .h6;
		}
		.c-list {
			@extend .list-unstyled;
			.c-item {
				@include font-size(17px);
			}
		}
	}
}
