$spacer: 1.2rem;

$spacers: map-merge((
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 2.5),
  7: ($spacer * 3.5),
  8: ($spacer * 5),
), $spacers);

$spacer-0: map-get($spacers,0);
$spacer-1: map-get($spacers,1);
$spacer-2: map-get($spacers,2);
$spacer-3: map-get($spacers,3);
$spacer-4: map-get($spacers,4);
$spacer-5: map-get($spacers,5);
$spacer-5: map-get($spacers,6);
$spacer-6: map-get($spacers,6);
$spacer-7: map-get($spacers,7);
$spacer-8: map-get($spacers,8);


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1900px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1360px,
  xxxl: 1610px
);
