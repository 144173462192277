








































































.c-install-prompt {
  .btn {
    margin: 0.2rem !important;
  }
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
}
