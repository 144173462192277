.c-update-location {
  .c-icon-picker {

    .c-icons {
      display: flex;
			justify-content: space-between;
			color: color(black);
      .c-icon{
				height: 50px;
        width: 50px;
				
				display: flex;
        justify-content: center;
        align-items: center;
				
				opacity: .6;
				border: 4px solid transparent;
				&:hover {
					opacity: .75;
					border: 1px solid color(brand);
					cursor: pointer;
				}
        &.selected {
					opacity: 1;
          border: 4px solid color(brand);
        }
      }
      img {
        height: 60%;
      }
    }
  }
}
